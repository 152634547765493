<template>
  <v-container fluid>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-form class="mt-3" @submit.prevent="updateUserInfo" v-model="form">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                    v-model="user.name"
                    label="نام"
                    align="end"
                    required
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="user.email"
                    label="ایمیل"
                    align="end"
                    required
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="user.phone"
                    label="موبایل"
                    align="end"
                    outlined
                ></v-text-field>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                        v-model="user.token"
                        label="توکن"
                        outlined
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="copy-field">
                    <v-btn x-small
                           fab
                           color="#EC255A"
                           @click="CopyToClipboard(user.token)">
                      <v-icon color="white">mdi-content-copy</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                        v-model="user.referral_code"
                        label="کد معرف"
                        outlined
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="copy-field">
                    <v-btn x-small
                           fab
                           color="#EC255A"
                           @click="CopyToClipboard(user.referral_code)">
                      <v-icon color="white">mdi-content-copy</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                        v-model="user.rtmp_link"
                        label="لینک RTMP"
                        outlined
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="copy-field">
                    <v-btn x-small
                           fab
                           color="#EC255A"
                           @click="CopyToClipboard(user.rtmp_link)">
                      <v-icon color="white">mdi-content-copy</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div class="avatar_section">
                  <img v-if="user.avatar_url" :src="user.avatar_url" alt="">
                  <img v-else src="../assets/defualt.jpeg" alt="">
                </div>
                <v-file-input type="file" @change="onFileUpload"
                ></v-file-input>
              </v-col>
              <v-btn type="submit" block color="#EC255A" dark>
                ذخیره اطلاعات
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Profile",
  data: () => ({
    txt: 'Profile',
    form: false,
    FILE: null,
    user: {
      id: '',
      name: '',
      token: '',
      email: '',
      phone: '',
      referral_code: '',
      avatar: '',
      avatar_url: '',
    }
  }),
  methods: {
    async getUser() {
      window.axios.get('user/user')
          .then((res) => {
            this.user = res.data;
          }).catch((err) => {
        console.log(err);
      })
    },
    onFileUpload(event) {
      this.FILE = event;
    },
    async updateUserInfo() {
      const formData = new FormData()
      if (this.FILE) {
        formData.append('avatar', this.FILE)
      }
      formData.append('name', this.user.name)
      formData.append('email', this.user.email)
      formData.append('phone', this.user.phone)
      window.axios.post('user/update-profile', formData)
          .then((res) => {
            this.$store.commit('login', res.data.data);
            this.user = res.data.data
            this.openSnackbar('اطلاعات شما با موفقیت ویرایش شدند!', 'success')
          })
          .catch((err) => {
            if (err.response.status === 422) {
              this.showValidationErrors(err.response.data.errors)
            } else if(err.response.status === 401) {
              this.openSnackbar(err.response.data.message, '#EC255A')
            }
          })
    },
    CopyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  },
  mounted() {
    this.getUser();
  }
}
</script>

<style scoped>
.avatar_section {
  height: 345px;
  display: flex;
  align-content: center;
}

.avatar_section > img {
  display: block;
  margin: auto;
  border-radius: 3px;
  max-width: 100%;
  max-height: 315px;
  height: auto;
  width: auto;
}

.copy-field {
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 42px !important;
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>